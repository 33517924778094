<template>
  <section id="Alumni">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
  import ExperiencesCarousel from '@/components/global/ExperiencesCarousel'

  export default {
    name: "Alumni",
    components: {
      ExperiencesCarousel
    },
    data() {
      return {
        carouselImages: [
          {
            image: 'Experiences/alumni.jpg',
            title: 'Alumni',
            description: 'Raider Alumni are a unique and exclusive addition to any event and provide exciting opportunities for guests to interact and take photos with legends.'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>